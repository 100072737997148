import { PlusOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import cn from 'classnames';
import React, { useState } from 'react';

import './FloatSelect.scss';

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  mode?: 'multiple' | 'tags' | undefined;
  required?: boolean;
  value: string | undefined;
  options: Array<{ value: string; label: string }>;
  onChange?: (value: string) => void;
  allowClear?: boolean;
};

const FloatSelect = ({
  label,
  name,
  placeholder,
  mode,
  required,
  onChange,
  value,
  options,
  allowClear = true,
  ...otherProps
}: Props) => {
  const requiredMark = required ? <span className="text-error">*</span> : null;

  const [isFocused, setIsFocused] = useState(false);
  const hasValue = value !== undefined && value !== '';

  const finalPlaceholder =
    !label && required ? `${placeholder || ''} *` : placeholder;

  return (
    <div
      className={cn(
        'float-select-label',
        { 'has-value': hasValue || isFocused },
        label && 'with-label',
        placeholder && 'with-placeholder',
      )}
    >
      {label && (
        <label
          htmlFor={name}
          className={cn('label', { 'has-value': hasValue || isFocused })}
        >
          {label} {requiredMark}
        </label>
      )}

      <Select
        id={name}
        onChange={onChange}
        options={options}
        suffixIcon={<PlusOutlined />}
        className="float-select"
        value={value || undefined}
        placeholder={finalPlaceholder}
        mode={mode}
        allowClear={allowClear}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          if (!hasValue) setIsFocused(false);
        }}
        {...otherProps}
      />
    </div>
  );
};

export default FloatSelect;
