'use client';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';

const CookieConsent = dynamic(() => import('react-cookie-consent'), {
  ssr: false,
});
const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Dismiss"
      enableDeclineButton
      cookieName="BryteCookieConsent"
      style={{ background: '#07405c', color: '#FFF' }}
      buttonStyle={{
        backgroundColor: '#ddb752',
        color: '#FFF',
        fontSize: '0.875rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#ddb752',
        borderRadius: '0.5rem',
        letterSpacing: '0.002rem',
        padding: '1rem 1.5rem',
        paddingBottom: '0.75rem',
        fontFamily: 'Fakt Pro',
        fontWeight: '500',
      }}
      declineButtonStyle={{
        backgroundColor: 'transparent',
        color: '#FFF',
        fontSize: '0.875rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#dfbb5d',
        borderRadius: '0.5rem',
        letterSpacing: '0.002rem',
        padding: '1rem 1.5rem',
        paddingBottom: '0.75rem',
        fontFamily: 'Fakt Pro',
        fontWeight: '500',
      }}
      expires={365} // Number of days before the cookie expires
      onAccept={() => {
        // Add functionality when user accepts cookies
      }}
      onDecline={() => {
        // Add functionality when user declines cookies
      }}
    >
      <strong>This site uses cookies to optimise your experience.</strong> By
      using our website, you consent to the use of cookies. You can read more in
      our{' '}
      <Link
        href="/legal?section=privacy"
        style={{ color: '#dddddd', textDecoration: 'underline' }}
      >
        privacy policy
      </Link>
      .
    </CookieConsent>
  );
};

export default CookieConsentBanner;
